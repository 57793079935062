import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="25" height="14" viewBox="0 0 25 14" fill="none" {...props}>
      <path
        d="M24.0391 6.60416C24.0391 3.76902 23.5925 1.46771 23.0432 1.46771C22.4939 1.46771 22.0474 3.76902 22.0474 6.60416C22.0474 9.43931 22.4939 11.7406 23.0432 11.7406C23.597 11.7406 24.0391 9.44379 24.0391 6.60416Z"
        fill="#D59C8A"
      />
      <path
        d="M23.0432 1.48029C22.4939 1.48029 22.0474 3.7816 22.0474 6.61674C22.0474 6.99805 22.0563 7.3659 22.0697 7.72029C22.3064 8.06571 22.5609 8.40216 22.8423 8.72515C23.1593 9.08851 23.4898 9.41599 23.8336 9.71655C23.9587 8.85524 24.0346 7.77861 24.0346 6.61225C24.0391 3.77711 23.597 1.48029 23.0432 1.48029Z"
        fill="#DEAA96"
      />
      <path
        d="M22.4269 5.79672C22.9226 6.36644 23.4585 6.8599 24.0167 7.27261C24.0212 7.05279 24.0256 6.83298 24.0256 6.60419C24.0256 3.76905 23.5791 1.46774 23.0298 1.46774C22.5653 1.46774 22.1724 3.12307 22.0652 5.35709C22.1813 5.50513 22.3019 5.65317 22.4269 5.79672Z"
        fill="#E3B5A2"
      />
      <path
        d="M22.7352 3.04223C23.0924 3.45046 23.4854 3.78242 23.9007 4.03812C23.7266 2.5084 23.4095 1.48111 23.0388 1.48111C22.8022 1.48111 22.5833 1.91176 22.4092 2.63401C22.5119 2.77307 22.6191 2.91214 22.7352 3.04223Z"
        fill="#E7CABD"
      />
      <path
        d="M23.2889 4.4958C23.4988 4.41954 23.7355 4.14589 23.5836 3.69729C23.5077 3.48197 23.4407 3.30701 23.2933 3.19038C23.137 3.06926 22.8959 3.04683 22.7887 3.0872C22.5788 3.16346 22.5208 3.54028 22.6592 3.93056C22.7932 4.31636 23.079 4.57206 23.2889 4.4958Z"
        fill="#CD7876"
      />
      <path
        d="M23.4317 3.9126C23.3557 3.69727 23.2888 3.52231 23.1414 3.40568C22.9851 3.28456 22.7439 3.26213 22.6368 3.3025C22.6278 3.30699 22.6189 3.31147 22.61 3.31596C22.5653 3.47745 22.5742 3.70175 22.6591 3.93503C22.7975 4.32531 23.0789 4.57652 23.2888 4.50475C23.3379 4.4868 23.3915 4.4554 23.4361 4.41503C23.4897 4.28045 23.4987 4.11446 23.4317 3.9126Z"
        fill="#D18683"
      />
      <path
        d="M22.945 5.05208C22.3778 5.09694 22.3957 5.88647 22.2885 6.7747C22.1903 7.58218 23.0521 7.85582 23.4362 7.69881C23.4496 7.69433 23.4585 7.68984 23.4719 7.68535C23.8917 7.40274 24.0882 6.60423 23.9408 6.05694C23.8158 5.62629 23.3156 5.02517 22.945 5.05208Z"
        fill="#CD7876"
      />
      <path
        d="M23.7757 6.3081C23.6596 5.87744 23.1549 5.27183 22.7843 5.30324C22.6637 5.31221 22.5699 5.35707 22.4985 5.42436C22.3734 5.74735 22.3511 6.24529 22.2841 6.77464C22.1859 7.58212 23.0478 7.85576 23.4318 7.69875C23.4452 7.69427 23.4541 7.68978 23.4675 7.68529C23.5301 7.64043 23.5881 7.5866 23.6417 7.5238C23.8203 7.16043 23.8784 6.68043 23.7757 6.3081Z"
        fill="#D18683"
      />
      <path
        d="M23.0832 8.31778C22.8823 8.38058 22.6233 8.61385 22.6679 8.99067C22.6903 9.15666 22.7126 9.29124 22.793 9.38544C22.8957 9.41684 23.0073 9.41684 23.0743 9.3989C23.2753 9.3361 23.4003 9.01759 23.3512 8.68563C23.3289 8.52862 23.3199 8.35815 23.1949 8.3088C23.1592 8.30432 23.119 8.30432 23.0832 8.31778Z"
        fill="#CD7876"
      />
      <path
        d="M23.2442 8.82474C23.2218 8.66773 23.2218 8.46586 23.0879 8.44791C23.0521 8.43894 23.0119 8.44343 22.9717 8.4524C22.8958 8.47483 22.8154 8.52418 22.744 8.59147C22.6815 8.69913 22.6502 8.82922 22.6681 8.99072C22.6904 9.1567 22.6904 9.31819 22.7931 9.38548C22.8824 9.4438 23.0075 9.41689 23.0745 9.39894C23.1057 9.38997 23.137 9.37203 23.1638 9.3496C23.2397 9.21502 23.2709 9.02212 23.2442 8.82474Z"
        fill="#D18683"
      />
      <path
        d="M23.7441 5.06093C23.82 5.03401 23.9049 4.93981 23.8558 4.7828C23.8334 4.70654 23.8066 4.64373 23.7575 4.60336C23.7039 4.5585 23.6146 4.54953 23.5789 4.56299C23.503 4.5899 23.4806 4.71999 23.5253 4.85906C23.57 4.99813 23.6682 5.08785 23.7441 5.06093Z"
        fill="#D18683"
      />
      <path
        d="M22.9628 10.5428C23.0521 10.5563 23.1905 10.498 23.2173 10.314C23.2307 10.2243 23.2396 10.148 23.2039 10.0808C23.1682 10.009 23.0833 9.95515 23.0387 9.95066C22.9494 9.93721 22.8556 10.0628 22.8332 10.2288C22.8154 10.3858 22.869 10.5294 22.9628 10.5428Z"
        fill="#CD7876"
      />
      <path
        d="M20.3013 6.60415C20.3013 3.38321 18.9347 0.767883 17.2467 0.767883C15.5587 0.767883 14.1921 3.37873 14.1921 6.60415C14.1921 9.82508 15.5587 12.4404 17.2467 12.4404C18.9347 12.4404 20.3013 9.82957 20.3013 6.60415Z"
        fill="#D59C8A"
      />
      <path
        d="M17.2468 0.776886C15.6927 0.776886 14.411 2.99296 14.219 5.85951C14.6388 6.9182 15.255 7.95446 16.0678 8.88754C16.9788 9.93278 18.0283 10.7268 19.1045 11.2427C19.8324 10.175 20.3013 8.49726 20.3013 6.60867C20.3013 3.39221 18.9348 0.776886 17.2468 0.776886Z"
        fill="#DEAA96"
      />
      <path
        d="M16.9297 6.03894C17.8943 7.14698 19.0063 7.96792 20.1495 8.47932C20.2567 7.8782 20.3192 7.2367 20.3192 6.5638C20.3192 3.34287 18.9527 0.727539 17.2646 0.727539C16.3491 0.727539 15.5275 1.49464 14.9692 2.71483C15.3846 3.8767 16.041 5.01614 16.9297 6.03894Z"
        fill="#E3B5A2"
      />
      <path
        d="M17.2689 0.727539C16.9786 0.727539 16.7017 0.803801 16.4382 0.947352C16.6526 1.52156 16.9831 2.08679 17.4252 2.59371C18.1933 3.47296 19.1445 4.00231 20.051 4.14137C19.5642 2.12717 18.5014 0.727539 17.2689 0.727539Z"
        fill="#E7CABD"
      />
      <path
        d="M18.524 3.48638C18.7428 3.44152 19.0152 3.20376 18.9304 2.74171C18.8902 2.51741 18.8455 2.33348 18.716 2.1989C18.582 2.05535 18.3453 1.99703 18.2292 2.01946C18.0104 2.06432 17.8988 2.42769 17.9747 2.83142C18.064 3.23516 18.3052 3.53124 18.524 3.48638Z"
        fill="#CD7876"
      />
      <path
        d="M15.4916 8.37169C15.3263 8.41655 15.1343 8.60945 15.2236 8.95487C15.2683 9.12534 15.3085 9.25992 15.4112 9.35412C15.5228 9.45282 15.7014 9.48422 15.7863 9.46179C15.9515 9.41693 16.0185 9.13431 15.9337 8.83375C15.8533 8.53319 15.6568 8.32683 15.4916 8.37169Z"
        fill="#D18683"
      />
      <path
        d="M16.9385 12.1084C17.4431 12.1757 18.6131 11.9379 18.7873 10.794C18.8721 10.2377 18.9972 9.8968 18.8007 9.15213C18.6801 8.69456 17.9031 8.34466 17.4342 8.47475C16.9429 8.61381 16.4071 9.1611 16.2731 10.166C16.1346 11.1708 16.4338 12.0411 16.9385 12.1084Z"
        fill="#CD7876"
      />
      <path
        d="M16.8271 2.00603C15.9339 1.75931 15.4114 2.30211 15.2149 3.24417C15.1167 3.70174 14.9693 4.08304 15.0676 4.7066C15.1256 5.09239 16.0098 4.98473 16.3626 4.89052C16.7333 4.79183 17.0235 4.46435 17.1888 3.63445C17.3585 2.81351 17.1888 2.10473 16.8271 2.00603Z"
        fill="#CD7876"
      />
      <path
        d="M17.0859 2.23032C16.2285 2.02397 15.7238 2.56229 15.5318 3.4864C15.4336 3.94397 15.2862 4.32528 15.3845 4.94883C15.3845 4.95332 15.3845 4.95332 15.3845 4.9578C15.6881 5.02061 16.1347 4.9578 16.3624 4.895C16.7331 4.79631 17.0234 4.46883 17.1886 3.63892C17.3092 3.05126 17.2556 2.52191 17.0859 2.23032Z"
        fill="#D18683"
      />
      <path
        d="M17.6397 8.82927C17.1976 8.95487 16.7108 9.44833 16.5902 10.3545C16.4652 11.2607 16.7376 12.0457 17.1931 12.1085C17.2065 12.1085 17.2199 12.113 17.2333 12.113C17.8004 12.0726 18.6445 11.7452 18.7829 10.7941C18.8588 10.2962 18.966 9.97319 18.8499 9.37656C18.6891 8.99525 18.0416 8.71712 17.6397 8.82927Z"
        fill="#D18683"
      />
      <path
        d="M15.7015 5.48719C15.3531 5.44233 14.8172 5.6756 14.7056 6.46962C14.6475 6.85542 14.6163 7.17392 14.7413 7.46102C14.8708 7.76159 15.1924 7.9814 15.371 8.00832C15.7193 8.05318 16.081 7.52831 16.1704 6.83299C16.2597 6.13766 16.0498 5.53654 15.7015 5.48719Z"
        fill="#CD7876"
      />
      <path
        d="M15.9248 5.86399C15.5764 5.81913 15.0405 6.0524 14.9289 6.84642C14.8798 7.18736 14.8485 7.47895 14.9289 7.73913C15.0763 7.89166 15.2549 7.99035 15.371 8.00381C15.7193 8.04866 16.0811 7.5238 16.1704 6.82848C16.215 6.49651 16.1882 6.18698 16.1123 5.94922C16.0543 5.90436 15.9917 5.87296 15.9248 5.86399Z"
        fill="#D18683"
      />
      <path
        d="M18.7249 2.93013C18.6847 2.70583 18.64 2.52191 18.5105 2.38733C18.3765 2.24378 18.1443 2.18995 18.0282 2.20789C17.9568 2.36041 17.9344 2.5892 17.9791 2.83593C18.0595 3.23967 18.3006 3.53574 18.5194 3.49088C18.5596 3.48191 18.5954 3.46845 18.6356 3.45051C18.7249 3.3249 18.7651 3.15443 18.7249 2.93013Z"
        fill="#D18683"
      />
      <path
        d="M19.5195 7.16495C19.3097 7.10663 18.9658 7.18738 18.8363 7.62252C18.7738 7.83336 18.7291 8.00831 18.7827 8.18327C18.8408 8.36719 19.0194 8.51972 19.131 8.55112C19.3409 8.60944 19.5999 8.34925 19.7071 7.96794C19.8143 7.58215 19.7294 7.22327 19.5195 7.16495Z"
        fill="#D18683"
      />
      <path
        d="M18.4971 6.58172C18.6311 6.55929 18.8008 6.42022 18.7606 6.14209C18.7382 6.00751 18.7159 5.89536 18.64 5.81461C18.5596 5.72938 18.4167 5.689 18.3452 5.70246C18.2113 5.72489 18.1354 5.94471 18.1756 6.18695C18.2202 6.42919 18.3631 6.60415 18.4971 6.58172Z"
        fill="#D18683"
      />
      <path
        d="M19.4842 6.47412C19.7566 6.4472 19.7432 5.67561 19.6851 5.22253C19.6226 4.73804 19.3815 4.28047 19.2386 4.29393C18.9661 4.32085 18.7563 4.9758 18.7965 5.37505C18.8411 5.76982 19.2118 6.50103 19.4842 6.47412Z"
        fill="#CD7876"
      />
      <path
        d="M19.5107 5.30769C19.4527 4.84563 19.2249 4.40601 19.0865 4.37909C18.8945 4.57647 18.7694 5.05647 18.8052 5.37049C18.8454 5.74283 19.1714 6.41124 19.4393 6.46507C19.5733 6.24975 19.5554 5.67554 19.5107 5.30769Z"
        fill="#D18683"
      />
      <path
        d="M12.772 6.58625C12.772 3.04232 9.91393 0.171295 6.386 0.171295C2.85807 0.171295 0 3.04232 0 6.58625C0 10.1302 2.85807 13.0012 6.386 13.0012C9.91393 13.0012 12.772 10.1257 12.772 6.58625Z"
        fill="#D59C8A"
      />
      <path
        d="M12.7719 6.58171C12.7719 3.03778 9.91388 0.166748 6.38595 0.166748C4.15755 0.166748 2.19709 1.31067 1.05386 3.04675C0.902029 5.24488 1.75498 7.77498 3.53235 9.81161C4.90333 11.3817 6.57351 12.3776 8.19457 12.732C10.8427 11.9514 12.7719 9.49311 12.7719 6.58171Z"
        fill="#DEAA96"
      />
      <path
        d="M6.38595 0.180237C5.07303 0.180237 3.84941 0.579489 2.83569 1.26136C3.04558 3.07818 3.87174 4.9937 5.27398 6.60417C7.24337 8.8651 9.8335 9.93277 12.0172 9.62323C12.4995 8.72155 12.7719 7.68977 12.7719 6.59519C12.7719 3.05575 9.91388 0.180237 6.38595 0.180237Z"
        fill="#E3B5A2"
      />
      <path
        d="M12.4235 4.45089C11.5438 1.96117 9.18138 0.180237 6.4037 0.180237C6.2206 0.180237 6.03751 0.189209 5.85888 0.202667C5.57754 1.43631 6.00178 2.99295 7.06016 4.20865C8.54279 5.90884 10.7087 6.29912 11.901 5.08342C12.0796 4.89949 12.2583 4.68865 12.4235 4.45089Z"
        fill="#E7CABD"
      />
      <path
        d="M7.42653 2.45009C7.35954 2.23476 7.0916 1.98803 6.64502 2.1271C6.4262 2.19439 6.25204 2.25719 6.13146 2.40074C6.00642 2.55326 5.97516 2.79551 6.01089 2.90317C6.07788 3.1185 6.453 3.19027 6.84152 3.06467C7.23003 2.93906 7.49351 2.66541 7.42653 2.45009Z"
        fill="#CD7876"
      />
      <path
        d="M2.11681 3.28451C2.0007 3.1589 1.74169 3.07815 1.47375 3.31591C1.34424 3.43255 1.246 3.53124 1.2058 3.6703C1.17008 3.81385 1.22367 3.99329 1.28619 4.0561C1.4023 4.18171 1.67917 4.10993 1.90692 3.8946C2.13914 3.68376 2.23292 3.41012 2.11681 3.28451Z"
        fill="#CD7876"
      />
      <path
        d="M4.77845 11.7002C4.69807 11.8482 4.70254 12.1263 5.01514 12.3013C5.16697 12.3865 5.29201 12.4493 5.43492 12.4404C5.58229 12.4314 5.73412 12.3192 5.77431 12.243C5.8547 12.0949 5.69839 11.8527 5.42598 11.7002C5.14911 11.5477 4.8633 11.5477 4.77845 11.7002Z"
        fill="#D18683"
      />
      <path
        d="M3.37599 7.26356C3.12144 7.27253 2.76418 7.48337 2.77311 8.02618C2.77758 8.29085 2.79098 8.50169 2.90709 8.68113C3.03213 8.86954 3.29114 8.98169 3.42064 8.97721C3.67519 8.96824 3.87168 8.58244 3.86275 8.10693C3.84936 7.6359 3.63053 7.25907 3.37599 7.26356Z"
        fill="#D18683"
      />
      <path
        d="M4.66682 4.67514C4.43014 4.58094 4.01483 4.64823 3.79154 5.18655C3.68436 5.44673 3.60398 5.66206 3.63971 5.88636C3.67543 6.11963 3.86299 6.33496 3.98357 6.3843C4.22025 6.47851 4.56858 6.17795 4.75614 5.70243C4.9437 5.23141 4.90797 4.76935 4.66682 4.67514Z"
        fill="#CD7876"
      />
      <path
        d="M6.72101 10.1929C7.13633 10.4934 8.28402 10.8299 8.96728 9.8968C9.29774 9.44371 9.57015 9.20147 9.73985 8.45231C9.84256 7.99025 9.32454 7.31736 8.8467 7.21418C8.34654 7.10651 7.61863 7.33979 7.03362 8.16072C6.4486 8.98614 6.31017 9.89231 6.72101 10.1929Z"
        fill="#CD7876"
      />
      <path
        d="M1.37538 6.4875C0.580481 6.60862 0.357194 7.22769 0.531358 8.04862C0.616207 8.44788 0.63407 8.80676 0.933274 9.26433C1.11637 9.54694 1.78176 9.15218 2.02738 8.95031C2.28639 8.73947 2.4025 8.3761 2.24173 7.65834C2.08097 6.94059 1.69691 6.43816 1.37538 6.4875Z"
        fill="#CD7876"
      />
      <path
        d="M11.2982 9.31372C11.0168 9.08493 10.1281 9.62774 9.90484 9.93727C9.79767 10.0853 9.68602 10.2019 9.61904 10.4397C9.57885 10.5877 9.71282 10.9152 10.0076 10.9332C10.1594 10.9421 10.3961 11.0274 10.856 10.5833C11.0927 10.3545 11.4098 9.40792 11.2982 9.31372Z"
        fill="#CD7876"
      />
      <path
        d="M11.0168 9.41682C10.865 9.29121 10.4899 9.46168 10.1683 9.68149C10.0522 9.77121 9.95843 9.86093 9.90484 9.93719C9.79767 10.0852 9.68602 10.2019 9.61904 10.4396C9.57885 10.5877 9.71282 10.9151 10.0076 10.9331C10.0567 10.9376 10.1147 10.9465 10.1907 10.9465C10.2889 10.8882 10.405 10.7985 10.539 10.6594C10.7712 10.4262 11.1151 9.50205 11.0168 9.41682Z"
        fill="#D18683"
      />
      <path
        d="M1.66111 6.57272C0.906403 6.71627 0.696513 7.32188 0.866211 8.12487C0.95106 8.52413 0.968923 8.883 1.26813 9.34058C1.26813 9.34506 1.27259 9.34506 1.27259 9.34955C1.53607 9.29123 1.871 9.08039 2.0273 8.95029C2.28631 8.73945 2.40242 8.37609 2.24166 7.65833C2.13001 7.14244 1.8978 6.74319 1.66111 6.57272Z"
        fill="#D18683"
      />
      <path
        d="M8.86892 7.62246C8.41788 7.52377 7.76142 7.73461 7.22999 8.47929C6.69857 9.22396 6.57353 10.0449 6.94865 10.3141C6.95759 10.323 6.97098 10.332 6.98438 10.3365C7.50687 10.5653 8.40002 10.6729 8.97163 9.89686C9.26637 9.49312 9.51645 9.25536 9.69061 8.67218C9.71741 8.26396 9.2753 7.7077 8.86892 7.62246Z"
        fill="#D18683"
      />
      <path
        d="M4.76044 4.97577C4.52375 4.88157 4.10844 4.94886 3.88515 5.48717C3.78691 5.72044 3.71546 5.91783 3.72439 6.11521C3.8003 6.24979 3.90748 6.34848 3.98786 6.37988C4.22455 6.47409 4.57288 6.16904 4.76044 5.69801C4.84975 5.47372 4.88994 5.24942 4.87655 5.06998C4.84529 5.0296 4.80509 4.9982 4.76044 4.97577Z"
        fill="#D18683"
      />
      <path
        d="M2.75091 9.46175C2.47403 9.68605 2.27754 10.2378 2.76877 10.8704C3.00992 11.1799 3.21534 11.4221 3.50562 11.5298C3.81375 11.6464 4.19781 11.5612 4.33624 11.449C4.61312 11.2247 4.47915 10.6012 4.0415 10.0539C3.60386 9.50213 3.02778 9.23745 2.75091 9.46175Z"
        fill="#CD7876"
      />
      <path
        d="M3.17522 9.56033C2.89835 9.78463 2.70186 10.3364 3.19309 10.9689C3.40744 11.2426 3.595 11.4669 3.83615 11.588C4.04604 11.588 4.24253 11.5252 4.33631 11.4489C4.61319 11.2246 4.47922 10.6011 4.04158 10.0538C3.83169 9.7936 3.59054 9.59622 3.36725 9.48407C3.2958 9.48856 3.22881 9.51547 3.17522 9.56033Z"
        fill="#D18683"
      />
      <path
        d="M6.85056 2.31554C6.63174 2.38283 6.45757 2.44564 6.337 2.58919C6.21196 2.73723 6.1807 2.97947 6.21196 3.09162C6.37273 3.14545 6.60048 3.14545 6.83716 3.06919C7.23015 2.94358 7.49363 2.66994 7.42217 2.45461C7.40878 2.41872 7.39091 2.37835 7.36858 2.34246C7.24354 2.27068 7.06938 2.24825 6.85056 2.31554Z"
        fill="#D18683"
      />
      <path
        d="M0.808317 5.06538C0.647551 5.02052 0.388539 5.07884 0.290293 5.40632C0.241169 5.56781 0.205444 5.69791 0.245635 5.828C0.285827 5.96706 0.424265 6.0837 0.504648 6.10613C0.665414 6.15099 0.861906 5.95809 0.942289 5.6665C1.02714 5.38389 0.964618 5.11024 0.808317 5.06538Z"
        fill="#CD7876"
      />
      <path
        d="M6.14949 11.2202C6.17182 11.3862 6.33258 11.6015 6.66751 11.5611C6.83275 11.5432 6.96672 11.5208 7.06943 11.431C7.18107 11.3368 7.2302 11.1664 7.22126 11.0811C7.19894 10.9152 6.93992 10.8165 6.64519 10.8523C6.35045 10.8882 6.12716 11.0542 6.14949 11.2202Z"
        fill="#CD7876"
      />
      <path
        d="M5.10004 6.35742C4.95267 6.31704 4.71152 6.37087 4.61774 6.68041C4.57308 6.82844 4.54182 6.95405 4.58202 7.07517C4.62221 7.20527 4.75171 7.31293 4.82763 7.33536C4.975 7.37573 5.15809 7.19181 5.23401 6.92265C5.30993 6.65349 5.24741 6.40228 5.10004 6.35742Z"
        fill="#D18683"
      />
      <path
        d="M1.50948 4.58549C1.42464 4.55857 1.28173 4.58998 1.22368 4.76942C1.19688 4.85465 1.17456 4.92643 1.19688 5.00269C1.21921 5.07895 1.29066 5.14624 1.33532 5.1597C1.42017 5.18661 1.53181 5.07895 1.58094 4.92194C1.62559 4.76493 1.59433 4.61689 1.50948 4.58549Z"
        fill="#CD7876"
      />
      <path
        d="M2.59027 3.68823C2.53668 3.64337 2.42057 3.62094 2.32679 3.73758C2.28213 3.79141 2.24641 3.84076 2.23748 3.89908C2.22855 3.96188 2.26427 4.03366 2.29553 4.05609C2.34912 4.10095 2.46076 4.05609 2.54561 3.95739C2.626 3.85422 2.64832 3.73309 2.59027 3.68823Z"
        fill="#D18683"
      />
      <path
        d="M1.78175 9.67705C1.70137 9.72191 1.62992 9.85201 1.7237 10.018C1.76836 10.0987 1.80855 10.1615 1.87553 10.1974C1.94698 10.2333 2.04523 10.2199 2.08542 10.1974C2.16581 10.1526 2.16134 9.99556 2.07649 9.85201C1.99611 9.70397 1.86214 9.62771 1.78175 9.67705Z"
        fill="#CD7876"
      />
      <path
        d="M8.83315 2.29759C8.85101 2.16301 8.76616 1.95666 8.48929 1.91628C8.35531 1.89834 8.24367 1.88937 8.14096 1.93423C8.03378 1.98806 7.95786 2.11367 7.94893 2.18096C7.93107 2.31554 8.11863 2.45011 8.35978 2.48152C8.60539 2.5174 8.81975 2.43217 8.83315 2.29759Z"
        fill="#D18683"
      />
      <path
        d="M6.64499 10.8523C6.62713 10.8523 6.61373 10.8568 6.59587 10.8613C6.45296 10.933 6.36811 11.0407 6.38151 11.1439C6.40384 11.3099 6.56461 11.5252 6.89954 11.4848C6.93973 11.4803 6.97992 11.4758 7.01564 11.4669C7.03351 11.4579 7.05137 11.4444 7.06923 11.431C7.18088 11.3368 7.23 11.1663 7.22107 11.0811C7.20321 10.9151 6.94419 10.8119 6.64499 10.8523Z"
        fill="#D18683"
      />
      <path
        d="M0.942351 5.67098C0.995939 5.48705 0.991474 5.30761 0.937885 5.19098C0.777119 5.14612 0.522572 5.20892 0.424326 5.53191C0.375203 5.69341 0.339477 5.8235 0.379668 5.9536C0.393066 5.99846 0.415394 6.03883 0.442189 6.0792C0.464517 6.09266 0.48238 6.10163 0.500243 6.10612C0.66101 6.15547 0.861968 5.96257 0.942351 5.67098Z"
        fill="#D18683"
      />
      <path
        d="M5.9305 1.24335C5.72061 0.781297 4.4836 1.22092 3.79141 1.56186C3.0501 1.92971 2.46062 2.60261 2.5678 2.83588C2.77769 3.29793 4.0147 3.60298 4.6265 3.32036C5.24724 3.03775 6.14485 1.70541 5.9305 1.24335Z"
        fill="#CD7876"
      />
      <path
        d="M9.01632 1.31517C9.06991 1.04152 8.3286 0.835168 7.88203 0.758907C7.39973 0.678159 6.8951 0.781337 6.86831 0.920402C6.81472 1.19405 7.3774 1.57984 7.77039 1.6561C8.1589 1.73237 8.96274 1.58881 9.01632 1.31517Z"
        fill="#CD7876"
      />
      <path
        d="M7.91324 0.95628C7.45327 0.880018 6.97543 0.969738 6.90845 1.09535C7.04242 1.33759 7.4622 1.59329 7.77033 1.65609C8.13652 1.72787 8.8689 1.60675 8.99841 1.3645C8.82871 1.16712 8.27496 1.0146 7.91324 0.95628Z"
        fill="#D18683"
      />
      <path
        d="M5.87697 1.7413C5.59563 1.12223 4.34522 1.52597 3.6575 1.87588C3.17073 2.12261 2.77328 2.5398 2.59912 2.88971C2.87153 3.32485 4.04155 3.5985 4.63103 3.32485C5.0776 3.1185 5.68048 2.35139 5.8859 1.7727C5.88143 1.75924 5.88143 1.75027 5.87697 1.7413Z"
        fill="#D18683"
      />
      <path
        d="M11.4904 8.34464C11.6556 8.37604 11.9146 8.28632 11.9861 7.93642C12.0218 7.76595 12.0441 7.62689 11.9905 7.49231C11.9369 7.35324 11.7851 7.24558 11.7002 7.22764C11.535 7.19623 11.3519 7.42053 11.2939 7.72558C11.2358 8.0396 11.3251 8.31324 11.4904 8.34464Z"
        fill="#CD7876"
      />
      <path
        d="M10.7314 3.82278C10.9324 3.71961 11.1288 3.41456 10.919 2.99288C10.8162 2.78652 10.7269 2.62503 10.5662 2.52634C10.3965 2.42764 10.1508 2.43662 10.0526 2.49045C9.85164 2.59363 9.84271 2.97493 10.0303 3.34278C10.2178 3.71064 10.5304 3.92596 10.7314 3.82278Z"
        fill="#CD7876"
      />
      <path
        d="M10.7938 3.22167C10.6911 3.01531 10.6018 2.85382 10.441 2.75513C10.2713 2.65643 10.0257 2.66541 9.92743 2.71924C9.9185 2.72372 9.90956 2.72821 9.90063 2.73718C9.87384 2.90316 9.9185 3.12746 10.0301 3.34279C10.2177 3.71064 10.5303 3.92597 10.7313 3.82279C10.7804 3.80036 10.825 3.76447 10.8697 3.71513C10.901 3.58055 10.8876 3.41457 10.7938 3.22167Z"
        fill="#D18683"
      />
      <path
        d="M10.4633 4.41944C9.90957 4.53608 10.0257 5.32112 10.0346 6.21384C10.0435 7.03028 10.9367 7.18729 11.2939 6.98094C11.3029 6.97645 11.3163 6.96748 11.3252 6.96299C11.7048 6.62655 11.7941 5.8101 11.5753 5.28524C11.4011 4.87253 10.825 4.34318 10.4633 4.41944Z"
        fill="#CD7876"
      />
      <path
        d="M11.4501 5.55437C11.276 5.14166 10.6999 4.61231 10.3382 4.68858C10.2221 4.71549 10.1328 4.76932 10.0702 4.84559C9.98539 5.18203 10.03 5.67549 10.0345 6.21381C10.0434 7.03026 10.9366 7.18727 11.2938 6.98091C11.3028 6.97643 11.3162 6.96746 11.3251 6.96297C11.3832 6.91362 11.4323 6.85082 11.4769 6.77904C11.6064 6.39325 11.5975 5.90876 11.4501 5.55437Z"
        fill="#D18683"
      />
      <path
        d="M11.2579 4.3252C11.3293 4.28932 11.4008 4.18165 11.3338 4.03362C11.2981 3.96184 11.2668 3.90352 11.2132 3.86763C11.1552 3.83175 11.0659 3.83623 11.0301 3.85418C10.9587 3.89006 10.9498 4.02464 11.0167 4.15474C11.0748 4.28034 11.1864 4.3566 11.2579 4.3252Z"
        fill="#D18683"
      />
    </Svg>
  )
}

export default Icon
