import React from 'react'
import styled from 'styled-components'
import { Flex } from 'packages/uikit'
import Footer from 'components/Menu/Footer'
import SubNav from 'components/Menu/SubNav'
import { PageMeta } from 'components/Layout/Page'

const StyledPage = styled.div<{ bg?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;
  padding-bottom: 0;
  min-height: calc(100vh - 64px);
  background: ${({ theme }) => theme.colors.gradients.bubblegum};

  position: relative;

  &:after {
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(${(props) => props.bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px;
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    min-height: calc(100vh - 64px);
  }
`

const Page: React.FC<React.HTMLAttributes<HTMLDivElement> & { bgUrl?: string }> = ({ children, bgUrl, ...props }) => {
  return (
    <>
      <PageMeta />
      <StyledPage bg={bgUrl} {...props}>
        <SubNav />
        {children}
        <Flex flexGrow={1} />
        {/* <Footer /> */}
      </StyledPage>
    </>
  )
}

export default Page
