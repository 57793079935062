import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="16" viewBox="0 0 20 16" fill="none" {...props}>
      <path
        d="M15.6629 15.9963C14.9331 16.1269 10.6481 12.7499 10.6481 12.7499C10.6481 12.7499 8.07154 15.5933 7.67345 15.355C7.08184 15.0031 5.53924 9.67939 5.53924 9.67939C5.53924 9.67939 0.507812 8.09022 0.507812 7.38645C0.507812 6.12647 18.1842 -0.457206 18.9085 0.0252189C20.1249 0.848179 16.8682 15.7806 15.6629 15.9963Z"
        fill="#D5CFE9"
      />
      <path
        d="M10.6535 12.7505C10.6535 12.7505 9.09986 14.7427 7.81159 15.3556C7.7563 15.384 7.61255 15.3556 7.61255 15.3556L8.13228 10.6392L10.6535 12.7505Z"
        fill="#7776A7"
      />
      <path
        d="M15.9892 2.83526C16.2601 3.01688 8.13243 10.6335 8.13243 10.6335C8.13243 10.6335 7.97762 15.3272 7.67352 15.3499C7.29202 15.3783 7.15932 14.8618 7.15932 14.8618C7.15932 14.8618 6.23044 12.0013 5.92081 11.0081C5.82682 10.7016 5.53931 9.67434 5.53931 9.67434C5.53931 9.67434 15.2981 2.37554 15.9892 2.83526Z"
        fill="#4B4777"
      />
      <path
        d="M17.3936 11.6835L14.8889 4.11795L14.7341 4.27687L17.2885 12.007C17.3217 11.9049 17.3549 11.797 17.3936 11.6835Z"
        fill="#7776A7"
      />
      <path
        d="M19.174 1.34256L12.0802 2.05768C11.8314 2.14849 11.5771 2.2393 11.3228 2.33579L19.1685 1.54688C19.174 1.47877 19.174 1.41067 19.174 1.34256Z"
        fill="#7776A7"
      />
      <path
        d="M19.1241 0.525299L15.4031 0.899887C15.1266 0.990696 14.8391 1.08718 14.5405 1.18934L19.1518 0.723944C19.1407 0.655837 19.1352 0.58773 19.1241 0.525299Z"
        fill="#7776A7"
      />
      <path
        d="M16.9677 13.1194L14.2253 4.78769L14.0483 4.96363L16.8737 13.4657C16.9069 13.3521 16.94 13.2386 16.9677 13.1194Z"
        fill="#7776A7"
      />
      <path
        d="M2.38752 8.54491L6.57854 4.19174C6.42372 4.25417 6.26891 4.3166 6.11963 4.38471L2.18848 8.45978C2.25483 8.48816 2.32117 8.51654 2.38752 8.54491Z"
        fill="#7776A7"
      />
      <path
        d="M3.26121 8.88538L8.55803 3.39142C8.40874 3.44817 8.25946 3.51061 8.11018 3.56736L3.05664 8.80592C3.12299 8.8343 3.19487 8.86268 3.26121 8.88538Z"
        fill="#7776A7"
      />
      <path
        d="M16.4371 5.56514C16.4371 4.51516 17.5927 3.62977 19.0247 3.41977C19.0302 3.34031 19.0468 3.27221 19.0524 3.19275C17.4766 3.40275 16.1938 4.35624 16.1938 5.50271C16.1938 6.49026 17.0785 7.33593 18.3281 7.68781C18.3336 7.64808 18.3446 7.60835 18.3502 7.56862C17.2222 7.23377 16.4371 6.46189 16.4371 5.56514Z"
        fill="#7776A7"
      />
      <path
        d="M9.77445 3.56174C8.97274 3.56174 8.32031 4.04984 8.32031 4.65145C8.32031 5.25306 8.97274 5.74116 9.77445 5.74116C10.5762 5.74116 11.2286 5.25306 11.2286 4.65145C11.2286 4.04984 10.5817 3.56174 9.77445 3.56174ZM9.77445 5.51413C9.13308 5.51413 8.61335 5.12252 8.61335 4.64577C8.61335 4.16335 9.13308 3.77741 9.77445 3.77741C10.4158 3.77741 10.9355 4.16902 10.9355 4.64577C10.9411 5.1282 10.4158 5.51413 9.77445 5.51413Z"
        fill="#7776A7"
      />
      <path
        d="M10.3328 12.478C10.7585 12.3304 11.3004 12.0977 12.0523 12.0807C13.7995 12.0467 15.2039 13.0002 15.2039 14.2091C15.2039 14.7085 14.9661 15.1626 14.557 15.5315L14.6123 15.5769C14.6454 15.5939 14.6731 15.6053 14.7007 15.6166C15.1652 15.2137 15.4472 14.7028 15.4472 14.1466C15.4472 12.8469 13.9211 11.7913 12.0413 11.7913C11.4497 11.7913 10.5318 11.9559 10.0066 12.2056L10.3328 12.478Z"
        fill="#7776A7"
      />
    </Svg>
  )
}

export default Icon
