import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="19" height="16" viewBox="0 0 19 16" fill="none" {...props}>
      <path
        d="M17.31 2.25219C17.1504 2.2892 17.0166 2.32092 16.893 2.34207C17.1659 2.14117 17.5263 1.77638 17.7888 1.40101C18.1595 0.867041 18.3243 0.306636 18.3243 0.306636C18.3243 0.306636 17.6241 0.687289 16.857 0.925196C16.3524 1.0838 16.0075 1.15782 15.7964 1.18954C15.1014 0.459955 14.1489 0 13.0986 0C11.0032 0 9.30937 1.74466 9.30937 3.89111C9.30937 4.16074 9.38659 4.67356 9.38659 4.67356C9.38659 4.67356 7.17792 4.51496 5.04132 3.51575C3.35778 2.73329 1.54038 0.63442 1.54038 0.63442C1.31385 0.597412 0.907124 1.72351 1.00494 3.02407C1.12851 4.71057 2.66274 5.73093 2.66274 5.73093C2.66274 5.73093 2.27661 5.77322 1.93166 5.70978C1.42712 5.61462 0.948312 5.31327 0.948312 5.31327C1.01009 6.45523 1.32415 7.2694 1.83899 7.85624C2.85838 9.02463 3.92925 9.10922 3.92925 9.10922L2.35383 9.16737C2.92531 11.5147 5.19577 11.7368 5.82388 11.7685C5.82388 11.7685 4.85082 12.6831 3.30115 13.0796C1.88533 13.4497 0.170898 13.3651 0.170898 13.3651C0.170898 13.3651 5.11339 17.1928 11.6004 13.6823C14.3754 12.1809 15.9508 8.91889 16.234 8.14701C16.5326 7.3487 17.0423 5.69392 16.9651 3.95984C16.9599 3.8964 16.9599 3.82767 16.9496 3.76423C17.2328 3.64263 17.8094 3.16681 18.1647 2.8126C18.6435 2.34207 18.834 1.7658 18.834 1.7658C18.834 1.7658 18.1029 2.05658 17.31 2.25219Z"
        fill="url(#paint0_linear)"
      />
      <path
        opacity="0.3"
        d="M12.3828 6.36534C9.74682 8.33205 6.49301 5.75207 3.2495 6.10629C2.9303 6.1433 1.95724 6.25961 1.08716 6.38649C1.23646 6.98919 1.48874 7.47029 1.82338 7.85623C2.28674 8.3902 2.76555 8.69684 3.14138 8.8713C3.50692 8.75499 3.91365 8.68098 4.35641 8.68098C6.58054 8.67569 7.74408 10.082 10.0351 9.8758C12.2335 9.68019 13.6081 8.02541 14.9622 7.24825C15.5851 6.89403 16.1618 6.67727 16.6921 6.56096C16.8671 5.79437 16.9958 4.89032 16.9495 3.95983C16.9443 3.89639 16.9443 3.82766 16.934 3.76422C17.2172 3.64262 17.7938 3.16681 18.1491 2.81259C15.9146 3.43115 13.361 5.63576 12.3828 6.36534Z"
        fill="#3A1D77"
      />
      <path
        opacity="0.3"
        d="M2.33838 9.16733C2.34353 9.1832 2.34868 9.20434 2.35382 9.2202C2.38986 9.19906 2.4259 9.1832 2.46194 9.16205L2.33838 9.16733Z"
        fill="#3A1D77"
      />
      <path
        opacity="0.3"
        d="M12.1307 10.875C9.5256 12.6303 6.30268 10.3411 3.09521 10.5948C4.00649 11.5993 5.36052 11.7262 5.82388 11.7526C5.82388 11.7526 4.9538 12.5668 3.55342 12.9951C3.74906 12.9528 3.93956 12.9316 4.1146 12.9316C6.33872 12.9369 7.50742 14.2163 9.79847 14.0366C10.823 13.9573 11.6725 13.5925 12.419 13.1484C14.5041 11.6469 15.7655 9.24669 16.1568 8.33206C14.4578 9.15681 12.8566 10.3886 12.1307 10.875Z"
        fill="#3A1D77"
      />
      <path
        opacity="0.3"
        d="M0.170898 13.3493C0.170898 13.3493 0.536437 13.6348 1.20573 13.9784C1.77206 13.6559 2.46195 13.3176 3.10035 13.1167C1.73087 13.4233 0.170898 13.3493 0.170898 13.3493Z"
        fill="#3A1D77"
      />
      <path
        opacity="0.3"
        d="M0.958697 5.46127C0.999885 5.44013 1.04107 5.41898 1.08226 5.40312C0.999885 5.36082 0.953549 5.3291 0.953549 5.3291C0.9484 5.3714 0.953549 5.41898 0.958697 5.46127Z"
        fill="#3A1D77"
      />
      <path
        opacity="0.3"
        d="M17.1146 2.17813C17.3411 1.97194 17.5934 1.69703 17.7942 1.41682C18.1649 0.882854 18.3296 0.32245 18.3296 0.32245C18.3296 0.32245 17.6294 0.703102 16.8572 0.946296C16.3526 1.1049 16.0077 1.17892 15.7966 1.21064C15.2766 0.660807 14.6176 0.264295 13.8814 0.100403C13.2172 0.581505 12.5737 1.24236 11.8735 2.09883C11.0703 3.08218 10.1951 3.55271 9.30955 3.74303C9.30955 3.7959 9.3044 3.85406 9.3044 3.90692C9.3044 4.17655 9.38163 4.68938 9.38163 4.68938C9.38163 4.68938 7.17295 4.53077 5.0312 3.53156C4.85616 3.45226 4.68626 3.35709 4.51121 3.25136C4.44943 3.24607 4.3928 3.24078 4.33617 3.24078C3.13658 3.20906 2.14293 3.42053 1.18018 3.86463C1.36552 4.37745 1.66413 4.79511 1.94729 5.10704C4.44943 4.56249 7.30681 6.22256 9.51034 5.98994C12.3162 5.69387 13.0267 3.76947 15.7811 2.55878C16.157 2.39489 16.6255 2.27329 17.1146 2.17813Z"
        fill="#3A1D77"
      />
      <path
        opacity="0.3"
        d="M18.8393 1.78693C18.8393 1.78693 18.6076 1.8768 18.2627 1.98783C18.448 1.96139 18.6231 1.93496 18.7878 1.91381C18.8239 1.83451 18.8393 1.78693 18.8393 1.78693Z"
        fill="#3A1D77"
      />
      <path
        d="M13.7011 3.00821C13.7011 3.11924 13.6136 3.20911 13.5054 3.20911C13.3973 3.20911 13.3098 3.11924 13.3098 3.00821C13.3098 2.89719 13.3973 2.80731 13.5054 2.80731C13.6136 2.8126 13.7011 2.89719 13.7011 3.00821Z"
        fill="#EFEFEF"
      />
      <path
        d="M16.1569 5.32913C16.1569 5.44016 16.0694 5.53003 15.9613 5.53003C15.8531 5.53003 15.7656 5.44016 15.7656 5.32913C15.7656 5.21811 15.8531 5.12823 15.9613 5.12823C16.0694 5.12823 16.1569 5.21811 16.1569 5.32913Z"
        fill="#EFEFEF"
      />
      <path
        d="M15.1322 3.23557C15.1322 3.34659 15.0447 3.43647 14.9366 3.43647C14.8285 3.43647 14.741 3.34659 14.741 3.23557C14.741 3.12454 14.8285 3.03467 14.9366 3.03467C15.0447 3.03467 15.1322 3.12454 15.1322 3.23557Z"
        fill="#EFEFEF"
      />
      <path
        d="M14.7409 9.37886C14.7409 9.48988 14.6534 9.57976 14.5453 9.57976C14.4371 9.57976 14.3496 9.48988 14.3496 9.37886C14.3496 9.26783 14.4371 9.17795 14.5453 9.17795C14.6534 9.17795 14.7409 9.26783 14.7409 9.37886Z"
        fill="#EFEFEF"
      />
      <path
        d="M11.9558 6.95749C11.9558 7.06852 11.8682 7.15839 11.7601 7.15839C11.652 7.15839 11.5645 7.06852 11.5645 6.95749C11.5645 6.84647 11.652 6.75659 11.7601 6.75659C11.8682 6.75659 11.9558 6.84647 11.9558 6.95749Z"
        fill="#EFEFEF"
      />
      <path
        d="M14.9367 6.53983C14.9367 6.65085 14.8492 6.74073 14.7411 6.74073C14.6329 6.74073 14.5454 6.65085 14.5454 6.53983C14.5454 6.4288 14.6329 6.33893 14.7411 6.33893C14.8492 6.33893 14.9367 6.4288 14.9367 6.53983Z"
        fill="#EFEFEF"
      />
      <path
        d="M4.02214 4.39865C4.02214 4.50968 3.93462 4.59955 3.8265 4.59955C3.71838 4.59955 3.63086 4.50968 3.63086 4.39865C3.63086 4.28763 3.71838 4.19775 3.8265 4.19775C3.93462 4.19775 4.02214 4.28763 4.02214 4.39865Z"
        fill="#EFEFEF"
      />
      <path
        d="M12.3264 11.1975C12.3264 11.3085 12.2388 11.3984 12.1307 11.3984C12.0226 11.3984 11.9351 11.3085 11.9351 11.1975C11.9351 11.0865 12.0226 10.9966 12.1307 10.9966C12.2388 10.9966 12.3264 11.0865 12.3264 11.1975Z"
        fill="#EFEFEF"
      />
      <path
        d="M5.13396 13.7458C5.13396 13.8568 5.04643 13.9467 4.93832 13.9467C4.8302 13.9467 4.74268 13.8568 4.74268 13.7458C4.74268 13.6347 4.8302 13.5449 4.93832 13.5449C5.04643 13.5449 5.13396 13.6347 5.13396 13.7458Z"
        fill="#EFEFEF"
      />
      <path
        d="M3.1259 6.95749C3.1259 7.06852 3.03838 7.15839 2.93026 7.15839C2.82214 7.15839 2.73462 7.06852 2.73462 6.95749C2.73462 6.84647 2.82214 6.75659 2.93026 6.75659C3.03838 6.75659 3.1259 6.84647 3.1259 6.95749Z"
        fill="#EFEFEF"
      />
      <path
        d="M4.47484 6.33362C4.47484 6.41821 4.40791 6.48165 4.33068 6.48165C4.2483 6.48165 4.18652 6.41292 4.18652 6.33362C4.18652 6.24903 4.25345 6.18559 4.33068 6.18559C4.40791 6.1803 4.47484 6.24903 4.47484 6.33362Z"
        fill="#EFEFEF"
      />
      <path
        d="M7.34764 9.23083C7.34764 9.31542 7.28071 9.37886 7.20348 9.37886C7.12626 9.37886 7.05933 9.31013 7.05933 9.23083C7.05933 9.14624 7.12626 9.08279 7.20348 9.08279C7.28071 9.08279 7.34764 9.14624 7.34764 9.23083Z"
        fill="#EFEFEF"
      />
      <path
        d="M13.2892 8.88186C13.2892 8.99288 13.2017 9.08275 13.0936 9.08275C12.9855 9.08275 12.8979 8.99288 12.8979 8.88186C12.8979 8.77084 12.9855 8.68097 13.0936 8.68097C13.2017 8.68097 13.2892 8.77084 13.2892 8.88186Z"
        fill="#EFEFEF"
      />
      <path
        d="M10.2723 12.2707C10.2723 12.4241 10.1487 12.5509 9.99942 12.5509C9.85012 12.5509 9.72656 12.4241 9.72656 12.2707C9.72656 12.1174 9.85012 11.9905 9.99942 11.9905C10.1539 11.9905 10.2723 12.1174 10.2723 12.2707Z"
        fill="#EFEFEF"
      />
      <path
        d="M13.6805 4.88505C13.6805 5.10709 13.5055 5.28684 13.2892 5.28684C13.073 5.28684 12.8979 5.10709 12.8979 4.88505C12.8979 4.663 13.073 4.48325 13.2892 4.48325C13.5055 4.48853 13.6805 4.663 13.6805 4.88505Z"
        fill="#EFEFEF"
      />
      <path
        d="M11.0392 10.1772C11.0392 10.2882 10.9517 10.3781 10.8436 10.3781C10.7355 10.3781 10.6479 10.2882 10.6479 10.1772C10.6479 10.0661 10.7355 9.97626 10.8436 9.97626C10.9517 9.98154 11.0392 10.0714 11.0392 10.1772Z"
        fill="#EFEFEF"
      />
      <path
        d="M9.49992 11.1975C9.49992 11.3085 9.4124 11.3984 9.30428 11.3984C9.19617 11.3984 9.10864 11.3085 9.10864 11.1975C9.10864 11.0865 9.19617 10.9966 9.30428 10.9966C9.4124 10.9966 9.49992 11.0865 9.49992 11.1975Z"
        fill="#EFEFEF"
      />
      <path
        d="M14.5812 1.42215C14.5812 1.53317 14.4937 1.62305 14.3856 1.62305C14.2775 1.62305 14.1899 1.53317 14.1899 1.42215C14.1899 1.31113 14.2775 1.22125 14.3856 1.22125C14.4937 1.22125 14.5812 1.31113 14.5812 1.42215Z"
        fill="#EFEFEF"
      />
      <path
        d="M8.36198 13.5449C8.36198 13.6559 8.27446 13.7458 8.16634 13.7458C8.05823 13.7458 7.9707 13.6559 7.9707 13.5449C7.9707 13.4338 8.05823 13.344 8.16634 13.344C8.27446 13.3493 8.36198 13.4338 8.36198 13.5449Z"
        fill="#EFEFEF"
      />
      <path
        d="M10.5502 3.93341C10.5502 3.9757 10.5193 4.00742 10.4781 4.00742C10.4369 4.00742 10.406 3.9757 10.406 3.93341C10.406 3.89111 10.4369 3.85939 10.4781 3.85939C10.5193 3.8541 10.5502 3.89111 10.5502 3.93341Z"
        fill="#EFEFEF"
      />
      <path
        d="M10.1537 5.7944C10.1537 5.8367 10.1228 5.86842 10.0816 5.86842C10.0404 5.86842 10.0095 5.8367 10.0095 5.7944C10.0095 5.75211 10.0404 5.72038 10.0816 5.72038C10.1176 5.7151 10.1537 5.75211 10.1537 5.7944Z"
        fill="#EFEFEF"
      />
      <path
        d="M5.84444 6.21734C5.84444 6.26492 5.8084 6.30192 5.76206 6.30192C5.71573 6.30192 5.67969 6.26492 5.67969 6.21734C5.67969 6.16976 5.71573 6.13275 5.76206 6.13275C5.8084 6.13275 5.84444 6.16976 5.84444 6.21734Z"
        fill="#EFEFEF"
      />
      <path
        d="M8.69161 9.78063C8.69161 9.82822 8.65557 9.86523 8.60923 9.86523C8.56289 9.86523 8.52686 9.82822 8.52686 9.78063C8.52686 9.73305 8.56289 9.69604 8.60923 9.69604C8.65557 9.69604 8.69161 9.73305 8.69161 9.78063Z"
        fill="#EFEFEF"
      />
      <path
        d="M4.10445 13.7458C4.10445 13.7934 4.06841 13.8304 4.02207 13.8304C3.97574 13.8304 3.9397 13.7934 3.9397 13.7458C3.9397 13.6982 3.97574 13.6612 4.02207 13.6612C4.06841 13.6612 4.10445 13.6982 4.10445 13.7458Z"
        fill="#EFEFEF"
      />
      <path
        d="M5.10836 8.05181C5.10836 8.0994 5.07232 8.1364 5.02598 8.1364C4.97964 8.1364 4.9436 8.0994 4.9436 8.05181C4.9436 8.00423 4.97964 7.96722 5.02598 7.96722C5.06717 7.96722 5.10836 8.00423 5.10836 8.05181Z"
        fill="#EFEFEF"
      />
      <path
        d="M12.2028 2.67513C12.2028 2.72272 12.1668 2.75972 12.1205 2.75972C12.0741 2.75972 12.0381 2.72272 12.0381 2.67513C12.0381 2.62755 12.0741 2.59055 12.1205 2.59055C12.1668 2.59055 12.2028 2.62755 12.2028 2.67513Z"
        fill="#EFEFEF"
      />
      <path
        d="M2.8481 3.67437C2.8481 3.72195 2.81206 3.75896 2.76572 3.75896C2.71939 3.75896 2.68335 3.72195 2.68335 3.67437C2.68335 3.62679 2.71939 3.58978 2.76572 3.58978C2.80691 3.58978 2.8481 3.62679 2.8481 3.67437Z"
        fill="#EFEFEF"
      />
      <path
        d="M5.29878 7.02621C5.29878 7.07379 5.26274 7.1108 5.21641 7.1108C5.17007 7.1108 5.13403 7.07379 5.13403 7.02621C5.13403 6.97863 5.17007 6.94162 5.21641 6.94162C5.26274 6.94162 5.29878 6.97863 5.29878 7.02621Z"
        fill="#EFEFEF"
      />
      <path
        d="M7.43013 10.1771C7.43013 10.2247 7.39409 10.2617 7.34776 10.2617C7.30142 10.2617 7.26538 10.2247 7.26538 10.1771C7.26538 10.1296 7.30142 10.0926 7.34776 10.0926C7.39409 10.0926 7.43013 10.1296 7.43013 10.1771Z"
        fill="#EFEFEF"
      />
      <path
        d="M7.13692 12.5456C7.13692 12.5932 7.10088 12.6302 7.05454 12.6302C7.00821 12.6302 6.97217 12.5932 6.97217 12.5456C6.97217 12.4981 7.00821 12.4611 7.05454 12.4611C7.10088 12.4611 7.13692 12.4981 7.13692 12.5456Z"
        fill="#EFEFEF"
      />
      <path
        d="M5.84444 12.7783C5.84444 12.8259 5.8084 12.8629 5.76206 12.8629C5.71573 12.8629 5.67969 12.8259 5.67969 12.7783C5.67969 12.7307 5.71573 12.6937 5.76206 12.6937C5.8084 12.6937 5.84444 12.7307 5.84444 12.7783Z"
        fill="#EFEFEF"
      />
      <path
        d="M10.8437 2.5271C10.8437 2.61169 10.7768 2.67514 10.6996 2.67514C10.6172 2.67514 10.5554 2.60641 10.5554 2.5271C10.5554 2.44251 10.6223 2.37907 10.6996 2.37907C10.7768 2.37378 10.8437 2.44251 10.8437 2.5271Z"
        fill="#EFEFEF"
      />
      <path
        d="M8.36198 7.35401C8.36198 7.46503 8.27446 7.55491 8.16634 7.55491C8.05823 7.55491 7.9707 7.46503 7.9707 7.35401C7.9707 7.24298 8.05823 7.15311 8.16634 7.15311C8.27446 7.15311 8.36198 7.24298 8.36198 7.35401Z"
        fill="#EFEFEF"
      />
      <path
        d="M5.13396 10.2671C5.13396 10.3781 5.04643 10.468 4.93832 10.468C4.8302 10.468 4.74268 10.3781 4.74268 10.2671C4.74268 10.156 4.8302 10.0662 4.93832 10.0662C5.04643 10.0662 5.13396 10.156 5.13396 10.2671Z"
        fill="#EFEFEF"
      />
      <path
        d="M14.8182 4.99606C14.8182 5.03836 14.7873 5.07008 14.7462 5.07008C14.705 5.07008 14.6741 5.03836 14.6741 4.99606C14.6741 4.95377 14.705 4.92204 14.7462 4.92204C14.7822 4.91676 14.8182 4.95377 14.8182 4.99606Z"
        fill="#EFEFEF"
      />
      <path
        d="M11.5953 5.8948C11.5953 5.9371 11.5644 5.96882 11.5232 5.96882C11.4821 5.96882 11.4512 5.9371 11.4512 5.8948C11.4512 5.85251 11.4821 5.82079 11.5232 5.82079C11.5593 5.8155 11.5953 5.85251 11.5953 5.8948Z"
        fill="#EFEFEF"
      />
      <path
        d="M15.2455 2.27332C15.2455 2.31562 15.2146 2.34734 15.1734 2.34734C15.1322 2.34734 15.1013 2.31562 15.1013 2.27332C15.1013 2.23103 15.1322 2.19931 15.1734 2.19931C15.2094 2.19931 15.2455 2.23103 15.2455 2.27332Z"
        fill="#EFEFEF"
      />
      <path
        d="M14.3651 5.86839C14.3651 5.91068 14.3342 5.9424 14.293 5.9424C14.2518 5.9424 14.2209 5.91068 14.2209 5.86839C14.2209 5.82609 14.2518 5.79437 14.293 5.79437C14.3291 5.79437 14.3651 5.82609 14.3651 5.86839Z"
        fill="#EFEFEF"
      />
      <path
        d="M14.8182 8.33728C14.8182 8.37957 14.7873 8.41129 14.7462 8.41129C14.705 8.41129 14.6741 8.37957 14.6741 8.33728C14.6741 8.29498 14.705 8.26326 14.7462 8.26326C14.7822 8.25797 14.8182 8.29498 14.8182 8.33728Z"
        fill="#EFEFEF"
      />
      <path
        d="M10.8075 6.95744C10.8075 6.99973 10.7766 7.03145 10.7354 7.03145C10.6942 7.03145 10.6633 6.99973 10.6633 6.95744C10.6633 6.91514 10.6942 6.88342 10.7354 6.88342C10.7766 6.88342 10.8075 6.91514 10.8075 6.95744Z"
        fill="#EFEFEF"
      />
      <path
        d="M10.2259 8.40608C10.2259 8.44837 10.1951 8.48009 10.1539 8.48009C10.1127 8.48009 10.0818 8.44837 10.0818 8.40608C10.0818 8.36378 10.1127 8.33206 10.1539 8.33206C10.1951 8.33206 10.2259 8.36378 10.2259 8.40608Z"
        fill="#EFEFEF"
      />
      <path
        d="M13.7731 10.0926C13.7731 10.1349 13.7422 10.1666 13.701 10.1666C13.6598 10.1666 13.6289 10.1349 13.6289 10.0926C13.6289 10.0503 13.6598 10.0186 13.701 10.0186C13.7422 10.0186 13.7731 10.0503 13.7731 10.0926Z"
        fill="#EFEFEF"
      />
      <path
        d="M14.2362 2.09887C14.2362 2.14117 14.2053 2.17289 14.1641 2.17289C14.1229 2.17289 14.092 2.14117 14.092 2.09887C14.092 2.05658 14.1229 2.02486 14.1641 2.02486C14.2053 2.01957 14.2362 2.05658 14.2362 2.09887Z"
        fill="#EFEFEF"
      />
      <path
        d="M13.3868 7.15837C13.3868 7.20066 13.3559 7.23238 13.3148 7.23238C13.2736 7.23238 13.2427 7.20066 13.2427 7.15837C13.2427 7.11607 13.2736 7.08435 13.3148 7.08435C13.3508 7.08435 13.3868 7.11607 13.3868 7.15837Z"
        fill="#EFEFEF"
      />
      <path
        d="M12.2799 1.63362C12.2799 1.67591 12.249 1.70763 12.2078 1.70763C12.1666 1.70763 12.1357 1.67591 12.1357 1.63362C12.1357 1.59132 12.1666 1.5596 12.2078 1.5596C12.2439 1.5596 12.2799 1.59132 12.2799 1.63362Z"
        fill="#EFEFEF"
      />
      <path
        d="M16.6151 1.4909C16.6151 1.5332 16.5842 1.56492 16.543 1.56492C16.5018 1.56492 16.4709 1.5332 16.4709 1.4909C16.4709 1.44861 16.5018 1.41689 16.543 1.41689C16.5842 1.4116 16.6151 1.44861 16.6151 1.4909Z"
        fill="#EFEFEF"
      />
      <path
        d="M11.9195 8.88189C11.9195 8.92419 11.8887 8.95591 11.8475 8.95591C11.8063 8.95591 11.7754 8.92419 11.7754 8.88189C11.7754 8.8396 11.8063 8.80788 11.8475 8.80788C11.8835 8.80259 11.9195 8.8396 11.9195 8.88189Z"
        fill="#EFEFEF"
      />
      <path
        d="M11.6415 12.3553C11.6415 12.3976 11.6106 12.4293 11.5694 12.4293C11.5282 12.4293 11.4973 12.3976 11.4973 12.3553C11.4973 12.313 11.5282 12.2813 11.5694 12.2813C11.6054 12.276 11.6415 12.313 11.6415 12.3553Z"
        fill="#EFEFEF"
      />
      <path
        d="M5.37095 4.68412C5.37095 4.72642 5.34007 4.75814 5.29888 4.75814C5.2577 4.75814 5.22681 4.72642 5.22681 4.68412C5.22681 4.64183 5.2577 4.61011 5.29888 4.61011C5.34007 4.61011 5.37095 4.64183 5.37095 4.68412Z"
        fill="#EFEFEF"
      />
      <path
        d="M6.48791 7.55487C6.48791 7.59716 6.45702 7.62888 6.41583 7.62888C6.37464 7.62888 6.34375 7.59716 6.34375 7.55487C6.34375 7.51257 6.37464 7.48085 6.41583 7.48085C6.45702 7.47556 6.48791 7.51257 6.48791 7.55487Z"
        fill="#EFEFEF"
      />
      <path
        d="M7.97066 6.04815C7.97066 6.09573 7.93462 6.13274 7.88828 6.13274C7.84195 6.13274 7.80591 6.09573 7.80591 6.04815C7.80591 6.00057 7.84195 5.96356 7.88828 5.96356C7.93462 5.96356 7.97066 6.00057 7.97066 6.04815Z"
        fill="#EFEFEF"
      />
      <path
        d="M2.93037 4.59957C2.93037 4.64715 2.89434 4.68416 2.848 4.68416C2.80166 4.68416 2.76562 4.64715 2.76562 4.59957C2.76562 4.55199 2.80166 4.51498 2.848 4.51498C2.89434 4.51498 2.93037 4.55199 2.93037 4.59957Z"
        fill="#EFEFEF"
      />
      <path
        d="M9.3044 6.73544C9.3044 6.78303 9.26836 6.82004 9.22202 6.82004C9.17569 6.82004 9.13965 6.78303 9.13965 6.73544C9.13965 6.68786 9.17569 6.65085 9.22202 6.65085C9.26836 6.65085 9.3044 6.68786 9.3044 6.73544Z"
        fill="#EFEFEF"
      />
      <path
        d="M8.44454 12.3553C8.44454 12.4029 8.4085 12.4399 8.36216 12.4399C8.31582 12.4399 8.27979 12.4029 8.27979 12.3553C8.27979 12.3078 8.31582 12.2708 8.36216 12.2708C8.4085 12.2708 8.44454 12.3078 8.44454 12.3553Z"
        fill="#EFEFEF"
      />
      <path
        d="M16.5429 3.25668C16.5429 3.30426 16.5069 3.34127 16.4605 3.34127C16.4142 3.34127 16.3782 3.30426 16.3782 3.25668C16.3782 3.2091 16.4142 3.17209 16.4605 3.17209C16.5069 3.17209 16.5429 3.2091 16.5429 3.25668Z"
        fill="#EFEFEF"
      />
      <path
        d="M11.6774 3.95986C11.6774 4.00745 11.6414 4.04445 11.5951 4.04445C11.5487 4.04445 11.5127 4.00745 11.5127 3.95986C11.5127 3.91228 11.5487 3.87527 11.5951 3.87527C11.6414 3.87527 11.6774 3.91228 11.6774 3.95986Z"
        fill="#EFEFEF"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0.170898"
          y1="7.5831"
          x2="18.8413"
          y2="7.5831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#29094F" />
          <stop offset="0.3289" stopColor="#230746" />
          <stop offset="0.8591" stopColor="#13032E" />
          <stop offset="1" stopColor="#0E0227" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
